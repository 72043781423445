<template>
  <div>
    <div class="title">充值管理</div>
    <div class="header"><span></span> 账户信息</div>
    <div class="nav">
      <div class="nav-content">
        <div>账户余额：</div>
        <div class="account" v-if="accountInfo">￥{{accountInfo.normalBalanceAmount}}</div>
        <a-button type="primary" @click="showHkxx">充值</a-button>
      </div>
    </div>
    <div class="header"><span></span> 充值记录</div>
    <!-- 搜索 -->
    <div class="search">
      <span>充值时间：</span>
      <a-range-picker v-model="date" @change="onChange" />
      <div class="table-search-submitButtons">
        <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
        <a-button style="margin-left: 8px;" icon="reload" @click="resetSearchEnterprise">重置</a-button>
      </div>
    </div>
    <div class="content">
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        @change="onRaskChange"
        :rowKey="(record) => record.id"
      >
      </s-table>
    </div>
    <!-- 付款信息 -->
    <a-modal
      title="汇款信息"
      :visible="visiblehk"
      @ok="visiblehk=false"
      @cancel="visiblehk=false"
      width="700px"
    >
      <div>
        <div class="hkxx-header"><a-icon theme="filled" style="color:#1890ff;margin-right:4px" type="info-circle" />温馨提示:请通过网银线下转款到以下账户，并填写完善相关信息。该账户为银行监管账户，您的转款到账后会立即转入至指定名单</div>
        <ul class="hkxx-content">
          <li>
            <span class="left">付款账户</span>
            <span class="right">{{bankInfo.payEnterpriseName}}</span>
          </li>
          <li>
            <span class="left">收款账户名</span>
            <span class="right">{{bankInfo.bankAccountName}}</span>
          </li>
          <li>
            <span class="left">开户银行</span>
            <span class="right">{{bankInfo.bankName}}</span>
          </li>
          <li>
            <span class="left">银行卡号</span>
            <span class="right">{{bankInfo.bankNo}}</span>
          </li>
        </ul>
      </div>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/'
import {
  getRecharges,
  getBanknum,
  getRechargesAccount
} from '@/api/api'
import moment from 'moment'
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '主键ID',
          dataIndex: 'id'
        },
        {
          title: '充值金额',
          dataIndex: 'rechargeAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '钱包类型',
          dataIndex: 'walletType',
          customRender: text => text==='100401' ? '银行账户' : text==='100402' ? '支付宝账户' : '微信账户'
        },
        {
          title: '充值类型',
          dataIndex: 'rechargeType',
          customRender: text => text==='100901' ? '线上充值' : '线下充值'
        },
        {
          title: '充值状态',
          dataIndex: 'status',
          customRender: text => text==='101001' ? '充值中' : text==='101011' ? '充值成功' : '充值失败'
        },
        {
          title: '充值时间',
          dataIndex: 'rechargeTime',
        }
      ],
      pagenation: {
        showSizeChanger: true,
        pageNo: 1,
        pageSize: 10
      },
      date: [],
      queryParams: {
        rechargeTime_begin: undefined,
        rechargeTime_end: undefined,
      },
      visiblehk: false,
      bankInfo: '',
      accountInfo: ''
    }
  },
  created () {
    this.getRechargesAccount()
  },
  methods: {
    // 选择时间
    onChange (date, dateString) {
      this.queryParams.rechargeTime_begin = dateString[0]
      this.queryParams.rechargeTime_end = dateString[1]
    },
    // 获取银行卡号
    getBanknum () {
      getBanknum().then(res => {
        if (res.success) {
          this.bankInfo = res.result
        }
      })
    },
    // 获取账户余额
    getRechargesAccount () {
      getRechargesAccount().then(res => {
        if (res.success) {
          this.accountInfo = res.result
        }
      })
    },
    // 充值
    showHkxx () {
      this.getBanknum()
      this.visiblehk = true
    },
    // 获取订单列表
    raskData (parameter) {
      return getRecharges(Object.assign(parameter,this.queryParams)).then(res => {
        // res.result.data = res.result.records
        return res.result
      })
    },
    // 查询
    handleToSearchEnterprise () {
      this.$refs.raskTable.refresh(true)
    },
    // 重置
    resetSearchEnterprise () {
      this.queryParams = {
        rechargeTime_begin: undefined,
        rechargeTime_end: undefined,
      }
      this.date = []
      this.handleToSearchEnterprise()
    },
    // 表格改变
    onRaskChange (pagination, filters, sorter) {
      // console.log('params', pagination, filters, sorter)
    },
    // 审核
    cancelRask (record) {},
    // 复核
    fuheRask (record) {},
    // 详情
    raskDetail (record) {
      window.sessionStorage.setItem('submenu','/settlementDetail')
      window.sessionStorage.setItem('taskInfo',JSON.stringify(record))
      this.$router.push('/settlementDetail')
    },
  }
}
</script>
<style scoped lang="less">
.header{
  display: flex;
  align-items: center;
  span{
    width: 2px;
    height: 14px;
    background: #347CF5;
    margin-right: 8px;
  }
}
.nav{
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 0px;
  margin: 30px 0;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background:#f6ffed;
  .nav-content{
    display: flex;
    // flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    .account{
      font-weight: bold;
      font-size: 18px;
      margin: 10px 50px 10px 00px;
    }
  }
}
.hkxx-header{
  color: #333333;
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 24px;
}
.hkxx-content{
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  li{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000000;
    .left{
      width: 200px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #F0F0F0;
      border-left: 1px solid #F0F0F0;
    }
    .right{
      width: 466px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #F0F0F0;
      border-left: 1px solid #F0F0F0;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.title{
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search{
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.table-search-submitButtons{
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.table-point{
  display: flex;
  align-items: center;
  // justify-content: center;
  span{
    width: 4px;
    height: 4px;
    background: #1890FF;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>